import { addDoc, getFirestore, doc, getDoc, DocumentReference, collection, DocumentData, CollectionReference, getDocs } from "firebase/firestore";
 

import { Names } from "../models/Names";
import { app } from './firebase'
const firestore = getFirestore(app);


interface Status {
	isActive: boolean
}
	

async function uploadName(name: string, nachName: string) {
  const ref = await collection(firestore, "friendslist");

  try {
    addDoc(ref, {name: name, nachname: nachName});
  } catch (e) {
    console.log(e);
  }
}

async function uploadNameGaesteliste(name: string, nachName: string) {
	const ref = await collection(firestore, 'gaesteliste');

	try {
		addDoc(ref, {name: name, nachname: nachName})
	} catch (e) {
		console.log(e)
	}
}

async function changeFriendslistState(isActive: boolean) {
	const ref = await collection(firestore, 'friendslist-status');

	try {
		addDoc(ref, {isActive: isActive})
	} catch (e) {
		console.log(e)
	}
}

const getFriendslistStatus = async () => {
	let isActive: Status = {isActive: false};
	const db = getFirestore(app);
	const colRef = collection(db, 'friendslist-status') as CollectionReference<Status>
	const productSnap = await getDocs(colRef)
	console.log(colRef)
	productSnap.forEach(element => {
		isActive = (element.data())
	});
	
	return isActive
}


const getNames = async () => {
	let nameArray: Names[] = []
	const db = getFirestore(app);
	const colRef = collection(db, 'friendslist') as CollectionReference<Names>
	const productSnap = await getDocs(colRef)
	productSnap.forEach(element => {
		nameArray.push(element.data())
	});
	return nameArray
}


const getGaesteListeNames = async () => {
	let nameArray: Names[] = []
	const db = getFirestore(app);
	const colRef = collection(db, 'gaesteliste') as CollectionReference<Names>
	const productSnap = await getDocs(colRef)
	productSnap.forEach(element => {
		nameArray.push(element.data())
	});
	return nameArray
}



export {
	getNames,
	uploadName,
	uploadNameGaesteliste,
	getFriendslistStatus,
	changeFriendslistState,
	getGaesteListeNames
}

