import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import AdminPannel from './components/AdminPannel/AdminPannel';
import Friendslist from './components/Friendslist/Firendslist';
import Header from './components/Header/Header';
import './index.css';


const MainApp = () => {
  return (
    <>
    < Header /> 
    < h1 className='text-center' >Webseite in Bearbeitung</h1>
    </>
  )
}


const App: React.FC = () => {

  return (


    <div className="app">
      <Router>
        <Routes>
          <Route path='/' element={ MainApp() } />
          <Route path='/admin-login' element={ 
            <>
            < Header />
            < AdminPannel /> 
            </>
            } />
          <Route path='/friendslist' element={ < Friendslist /> } />
        </Routes>
      </Router>
     
    </div>
  )

}

export default App;
