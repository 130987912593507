// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCTLhuu794vvhi9MLefJb1CJyJaRFytQA8",
  authDomain: "serotonin-website.firebaseapp.com",
  projectId: "serotonin-website",
  storageBucket: "serotonin-website.appspot.com",
  messagingSenderId: "407535215826",
  appId: "1:407535215826:web:e44d8f3ad671f90aa7790b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export {
	app
}