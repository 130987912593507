import { useState } from "react";
import { getAuth, signInWithEmailAndPassword, signOut  } from "firebase/auth";

import './login_style.css'

interface Props {
	loggedIn: () => void
}

const AdminLogin: React.FC<Props> = ({ loggedIn }) => {

	const auth = getAuth();

const [message, setMessage] = useState('')
const [ login, setLogin ] = useState({email: '', password: ''})

const updateForm = (event: React.ChangeEvent<HTMLInputElement>) => {
	if(message) {
		setMessage('')
	}
	setLogin(prevValue => {
		return {...prevValue, 
				[event.target.name] : event.target.value
			}
	})
}

const submit = (e: React.FormEvent) => {
e.preventDefault()


signInWithEmailAndPassword(auth, login.email, login.password)
  .then((userCredential: any) => {
    const user = userCredential.user;
	loggedIn()
  })
  .catch((error: any) => {
	setMessage('Email or Password is wrong')
  });
}

const logout = () => {
	signOut(auth)
}


	return (
		<div className="login">
			<form className="login--form" onSubmit={submit} >
			
			<h2>Email</h2>
			<input 
			className="login--input"
			name='email'
			onChange={(e) => updateForm(e)}
			type={"string"}/>
				<h2>Password</h2>
			<input 
			className="login--input"
			onChange={(e) => updateForm(e)}
			name="password"
			type={"password"}/>
			{message && <p className="login--message" >{message}</p>}
			<button className="login--button" >LOGIN</button>
			</form>
		</div>
	)
}


export default AdminLogin