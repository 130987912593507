import { useState } from 'react'

import './adminpannel_style.css'
import FriendslistOverview from './Views/Friendslist/FriendslistOverview'

import AdminLogin from './Login/AdminLogin'
import Gaesteliste from './Views/Friendslist/Gaesteliste'

const AdminPannel = () => {

	const [ isLoggedIn, setIsLoggedIn ] = useState(false)

	function loggedIn() {
		setIsLoggedIn(true)
	}


	if(!isLoggedIn) {
		return < AdminLogin loggedIn={loggedIn} />
	}

	return (
		<div className="admin-pannel">
			<h1>AdminPannel</h1>
			< Gaesteliste />
			< FriendslistOverview />
		</div>
	)
}


export default AdminPannel