
import './header_style.css'


const Header: React.FC = () => {


	return (
		<div className="header">
			<p className='old-english header-logo' >S</p>
			<div className='header-divider' ></div>
		</div>
	)
}


export default Header