import { v4 } from 'uuid'
import { Font, Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';


import React, { useEffect, useState } from "react"
import { getNames, getGaesteListeNames } from "../../../../utils/firestore"
import { Names } from '../../../../models/Names'

import './friendslistoverview_style.css'


import Monterey from '../../../../assets/fonts/MontereyMediumFLF.ttf'
import OldEnglish from '../../../../assets/fonts/Olde English Regular.ttf'



const FriendslistOverview: React.FC = () => {

	let date = new Date()

	const [ gaestelisteNames, setGaesteNames ] = useState<Names[]>([])
	const [ names, setNames ] = useState<Names[]>([])
	const [ state, setState ] = useState<string>('start')


	/* const gaestelisteNames = [
		{name: 'Sebastian', nachname: 'Mosbach'},
		{name: 'Ines', nachname: 'Langen'},
		{name: 'Marcel', nachname: 'Otto'}
	] */

	useEffect(() => {
		async function getNamesX() {
			setState('loading')
			const x = await getNames()
			let y: Names[] = []
			y = x.sort((name1, name2) => compare(name1.nachname, name2.nachname))
			let lastValue = {name: '', nachname: ''}
			const z = y.filter((element, index) => {
				lastValue = y[index - 1] || {}
				return !(element.nachname === lastValue.nachname
				&&
				element.name === lastValue.name)
			}
				)
		
			setNames(z)
			
		}
		getNamesX()


		async function getNamesY() {
			setState('loading')
			const x = await getGaesteListeNames()
			let y: Names[] = []
			y = x.sort((name1, name2) => compare(name1.nachname, name2.nachname))
			let lastValue = {name: '', nachname: ''}
			const z = y.filter((element, index) => {
				lastValue = y[index - 1] || {}
				return !(element.nachname === lastValue.nachname
				&&
				element.name === lastValue.name)
			}
				)
		
			setGaesteNames(z)
			setState('done')



		}
		getNamesY()

		



	}, [])

	Font.register({ 
		family: 'Monterey', 
		src: Monterey
	})
	Font.register({
		family: 'OldEnglish',
		src: OldEnglish
	})

	const styles = StyleSheet.create({
		page: {
		  paddingBottom: 100,
		  paddingTop: 80,
		  flexDirection: 'column',
		  backgroundColor: '#FFFFFF'
		},
		logo: {
			fontSize: '40px',
			fontFamily: 'OldEnglish',
			position: 'absolute',
			bottom: 20,
			right: 20,
			color: 'grey'
		},
		date: {
			fontSize: '20px',
			fontFamily: 'Monterey',
			position: 'absolute',
			bottom: 25,
			left: 20,
			color: 'grey'
		},
		title: {
			fontFamily: 'Monterey',
			position: 'absolute',
			top: 20,
			fontSize: '25px',
			textAlign: 'center',
			marginLeft: 'auto',
			marginRight: 'auto',
			left: 0,
			right: 0,
		},
		divider: {
			position: 'absolute',
			borderBottom: '1px solid grey',
			top: 60,
			textAlign: 'center',
			marginLeft: 'auto',
			marginRight: 'auto',
			left: 30,
			right: 30,
		},
		bottomDivider: {
			position: 'absolute',
			borderBottom: '1px solid grey',
			bottom: 80,
			textAlign: 'center',
			marginLeft: 'auto',
			marginRight: 'auto',
			left: 30,
			right: 30,
		},
		rows: {
			flexDirection: 'row',
		},
		section: {
		  margin: 10,
		  marginBottom: '100px',
		  paddingLeft: 60,
		  paddingRight: 60,
		  paddingBottom: 100,
		  flexGrow: 1
		},
		pageNumber: {
			fontSize: 12,
			position: 'absolute',
			bottom: 30,
			left: 0,
			right: 0,
			textAlign: 'center',
			color: 'grey'
		}
	  });

	  const allNamesGaesteliste = gaestelisteNames
	  	.sort((name1, name2) => compare(name1.nachname, name2.nachname))
	  	.map((name) => <Text key={v4()}>{name.name} {name.nachname}</Text>)
		  const namesCheckGaesteliste = gaestelisteNames
	  	.map(() => <Text key={v4()}>O</Text>)

	  const allNames = names
	  	.sort((name1, name2) => compare(name1.nachname, name2.nachname))
	  	.map((name) => <Text key={v4()}>{name.name} {name.nachname}</Text>)
	  const namesCheck = names
	  	.map(() => <Text key={v4()} >O</Text>)


	  const MyDocument = () => (
		<Document>
		  <Page size="A4" style={styles.page}>
			<Text fixed style={styles.title} >Serotonin Friendslist</Text>
			<Text fixed style={styles.divider} ></Text>
			<View style={styles.rows} >

			<View style={styles.section}>
			  {allNames}
			</View>
			<View style={styles.section}>
				{namesCheck}
			</View>

			</View>
			<Text fixed style={styles.bottomDivider} ></Text>
			<Text
				style={styles.pageNumber}
				render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`} 
				fixed />
			<Text 
				style={styles.logo} 
				fixed>S</Text>
			<Text 
				style={styles.date} 
				fixed>{`${date.getDay()}.${date.getMonth()}.${date.getFullYear()}`}</Text>
		  </Page>

		  <Page size="A4" style={styles.page}>
			<Text fixed style={styles.title} >Serotonin Gästeliste</Text>
			<Text fixed style={styles.divider} ></Text>
			<View style={styles.rows} >

			<View style={styles.section}>
			  {allNamesGaesteliste}
			</View>
			<View style={styles.section}>
				{namesCheckGaesteliste}
			</View>

			</View>
			<Text fixed style={styles.bottomDivider} ></Text>
			<Text
				style={styles.pageNumber}
				render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`} 
				fixed />
			<Text 
				style={styles.logo} 
				fixed>S</Text>
			<Text 
				style={styles.date} 
				fixed>{`${date.getDay()}.${date.getMonth()}.${date.getFullYear()}`}</Text>
		  </Page>
		</Document>
	  );

	function compare(lastName1: string, lastName2: string) {
		if(lastName1[0] < lastName2[0]) {
			return -1
		}
		if(lastName1[0] > lastName2[0]) {
			return 1
		}
		return 0
	}

	const NameElements = names
		.sort((name, name2) => compare(name.nachname, name2.nachname ))
		.map((name, index) => {
			console.log()
			return <div className="names-list">
			
			<p>{index + 1}.</p>
			<p className='names-list--name' key={v4()} >{name.name} {name.nachname}</p>
		
			</div>
		})




	return (
		<>
			
			<h1>Friendslist Overview</h1>
			<PDFDownloadLink className='active-button glory' document={<MyDocument />} fileName='Friendslist'>
						{({loading, error}) => (loading ? "Loading document..." : 'Download')}
				</PDFDownloadLink>
			<div className="friendslistoverview--namelist">
				{ state === 'done' && <p>Gesamte Anzahl: {NameElements.length}</p>}
				{ state === 'loading' && <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> }
				{ state === 'done' && NameElements}
				
				
			</div>
			
		</>
	)
}

export default FriendslistOverview