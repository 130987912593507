import './friendslistoverview_style.css'

import React, { useState, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { uploadNameGaesteliste, getFriendslistStatus, changeFriendslistState } from '../../../../utils/firestore'


const Gaesteliste: React.FC = () => {

	async function getStatus() {
		const x = await getFriendslistStatus()
		setListActivity(x.isActive)
	}

	useEffect(()=> {
		getStatus()
	}, [])

	async function toogleActive() {
		
		const x = await getFriendslistStatus()
		changeFriendslistState(!x.isActive) 
		setListActivity(!x.isActive)
	}
	
	const [ listActivity, setListActivity ] = useState<boolean>(false)
	const [ name, setName ] = useState('')
	const [ nachName, setNachname ] = useState('')
	const [ state, setState ] = useState('add-name')
	const [ isCaptcha, setIsCaptch ] = useState(false)
	



	async function submit(e: React.FormEvent) {
		e.preventDefault()
		if(!isCaptcha) {
			return
		}
		if(name === '') {
			return
		}
		if(nachName === '') {
			return
		}
		setState('loading')
		await uploadNameGaesteliste(name, nachName)
		setState('finished')
		console.log('uploaded', name)
	}

	function newName() {
		reset()
		setName('')
		setNachname('')
		setIsCaptch(false)
		setState('add-name')
	}

	const recaptchaRef = React.createRef<any>();

	const reset = () => recaptchaRef.current.reset()


	return (
		<>
		<div className='row' >
		<h1 className='active-button' onClick={()=> toogleActive()} >Change</h1>
		<p >Liste Active:</p>
		<p className='activity-status' >{listActivity ? 'Is Active' : 'Is Deactivaded'}</p>
		</div>
		<h1 >Gästeliste Overview</h1>
			<ReCAPTCHA
						className="recaptcha"
						ref={recaptchaRef}
						theme="dark"
						sitekey="6Ldz5tohAAAAANs7QGXRZad0db9xhPXZRJfWgyAS"
						onChange={() => {
							setIsCaptch(true)
						}}
						/>,
			{ state === 'add-name' &&
			<form onSubmit={submit} className='friendslist--form'>
				<input 
				placeholder='Vorname'
				type={'string'}
				className={'friendslist-input'}
				value={name} 
				onChange={e => setName(e.target.value)}
				name={'name'} />
				<input 
				placeholder='Nachname'
				type={'string'}
				className={'friendslist-input'}
				value={nachName} 
				onChange={e => setNachname(e.target.value)}
				name={'name'} />
					 
				<button className='friendslist-button'>HINZUFÜGEN</button>
			</form> }
			{ state === 'loading' &&
				<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
			{ state === 'finished' && 
				<div className='friendslist--finished'> 
					<p className='friendslist--success' >Erfolg!</p>
					<p className='friendslist--success--message' >{name} wurde zur Gästeliste hinzugefügt. Wir freuen uns auf Dich!</p>
					<button className='friendslist-button' onClick={newName} >Weiteren Namen hinzugügen</button>
				</div> }
		</>
	)
}


export default Gaesteliste