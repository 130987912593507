// google Captcha
// site key: 6Ldz5tohAAAAANs7QGXRZad0db9xhPXZRJfWgyAS
// secret key: 6Ldz5tohAAAAALcAK4VDsZHUvCUcuZ14BVAZz_8e

import ReCAPTCHA from "react-google-recaptcha";



import React, { useState, useEffect } from 'react'
import './friendslist_style.css'

import { uploadName, getFriendslistStatus } from '../../utils/firestore'
import { stat } from "fs";




const Friendslist: React.FC = () => {


	const [ isActive, setIsActive ] = useState(false)
	const [ name, setName ] = useState('')
	const [ nachName, setNachname ] = useState('')
	const [ state, setState ] = useState('add-name')
	const [ isCaptcha, setIsCaptch ] = useState(false)
	
	useEffect(()=> {
		async function getStatus() {
			const status = await getFriendslistStatus()
			console.log(status)
			setIsActive(status.isActive)
			if(!status) {
				setState('')
			}
		}
		getStatus()
		

	}, [])


	async function submit(e: React.FormEvent) {
		e.preventDefault()
		if(!isCaptcha) {
			return
		}
		if(name === '') {
			return
		}
		if(nachName === '') {
			return
		}
		setState('loading')
		await uploadName(name, nachName)
		setState('finished')
		console.log('uploaded', name)
	}

	function newName() {
		reset()
		setName('')
		setNachname('')
		setIsCaptch(false)
		setState('add-name')
	}

	const recaptchaRef = React.createRef<any>();

	const reset = () => recaptchaRef.current.reset()



	return (
		<div className="friendslist">
			<img className="friendslist-logo" src={require('../../assets/images/logo.png')} ></img>
			<h1>Upcoming Event</h1>
			<img className='friendslist-image' src={require('../../assets/images/art-of-house2.jpeg')} ></img>
			{!isActive && <p  className='firendslist-formtitle'>Eintragungen in die Gästeliste sind nicht mehr möglich.</p>}
			
			
			{isActive && <p className='firendslist-formtitle' >Willst du auf die Freundesliste und ermäßigten Eintritt erhalten?</p>}
			{isActive && <p className='friendslist-formexplanation'>Trage dich hier mit deinem vollständigen Namen wie auf deinem Ausweis ein: </p>}
			{isActive && <ReCAPTCHA
						className="recaptcha"
					  	ref={recaptchaRef}
					  	theme="dark"
						sitekey="6Ldz5tohAAAAANs7QGXRZad0db9xhPXZRJfWgyAS"
						onChange={() => {
								setIsCaptch(true)
							}}
						/>}
			{ state === 'add-name' && isActive &&
			<form onSubmit={submit} className='friendslist--form'>
				<input 
				placeholder='Vorname'
					type={'string'}
					className={'friendslist-input'}
					value={name} 
					onChange={e => setName(e.target.value)}
					name={'name'} />
				<input 
				placeholder='Nachname'
					type={'string'}
					className={'friendslist-input'}
					value={nachName} 
					onChange={e => setNachname(e.target.value)}
					name={'name'} />
					 
				<button className='friendslist-button'>HINZUFÜGEN</button>
			</form> }
			{ state === 'loading' && isActive &&
				<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
			{ state === 'finished' && isActive && 
				<div className='friendslist--finished'> 
					<p className='friendslist--success' >Erfolg!</p>
					<p className='friendslist--success--message' >{name} wurde zur Freundesliste hinzugefügt. Wir freuen uns auf Dich!</p>
					<button className='friendslist-button' onClick={newName} >Weiteren Namen hinzugügen</button>
				</div> }
		</div>
	)
}


export default Friendslist